/* The Modal (background) */
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  /* Modal Content */
  .modal-content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 60%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s
  }
  
  /* Add Animation */
  @-webkit-keyframes animatetop {
    from {top:-300px; opacity:0} 
    to {top:0; opacity:1}
  }
  
  @keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
  }
  
  /* The Close Button */
  .close {
    color: white;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
  }
  
  .modal-header {
    padding: 2px 16px;
    background-color: #5cb85c;
    color: white;
  }
  
  .modal-body {padding: 2px 16px;}
  
  .modal-footer {
    padding: 2px 16px;
    background-color: #5cb85c;
    color: white;
  }
  .loader:empty {
    /* position: absolute;
    top: calc(50% - 4em);
    left: calc(50% - 4em);
    width: 6em;
    height: 6em;
    border: 1.1em solid rgba(0, 0, 0, 0.2);
    border-left: 1.1em solid #000000;
    border-radius: 50%;
    animation: load8 1.1s infinite linear; */
    position: absolute;
    top: calc(50% - 4em);
    left: calc(50% - 4em);
    width: 4em;
    height: 4em;
    border: 0.5em solid rgb(247 219 249);
    border-left: 0.5em solid #9709a0;
    border-radius: 50%;
    animation: load8 1.1s infinite linear
  }
  
  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 
  .preloader-img {
    position:absolute;
    top:40%;
    left:46%;
   /* right:0; */
   /* bottom:0;*/
	margin:auto;
	width:20%;
}
.preloader {
	position:fixed; width:100%; height:100%;  
	padding:0px; top:0px; z-index:+99999; display:block;
	background-color: #fff;opacity: 0.90;
}
.fullScreenbtn{

  color:#fff;
  border: none;
   background-color: transparent !important;
  width: 3%;
  opacity: 1;
  position: absolute;
  float:right;
  right:0; 
  bottom: 2px; 
  font-size: 1.5vw;
}
.fullScreenSL{

  color:#0f0f55;
  border: none;
   background-color: transparent !important;
  width: 3%;
  opacity: 1;
  position: absolute;
  float:right;
  right:0; 
  bottom: 2px; 
  font-size: 1.5vw;
}
.diagramposition{
  position: inherit !important;
  float: none !important;
}
#diagram_diagramLayer_div{
  width: 100% !important;
  position: inherit !important;
}
#diagramcontent{
  
  position: inherit !important;
  text-align: center;
}
  /* Book Loader */
  /* * {
    box-sizing: border-box;
  }
  
  body {
    background: rebeccapurple;
  }
  
  h1 {
    color: #FFFFFF;
    text-align: center;
    font-family: sans-serif;
    text-transform: uppercase;
    font-size: 20px;
    position: relative;
  }
  
  h1:after {
    position: absolute;
    content: "";
    -webkit-animation: Dots 2s cubic-bezier(0, .39, 1, .68) infinite;
    animation: Dots 2s cubic-bezier(0, .39, 1, .68) infinite;
  }
  
  .loader {
    margin: 5% auto 30px;
  }
  
  .book {
    border: 4px solid #FFFFFF;
    width: 60px;
    height: 45px;
    position: relative;
    perspective: 150px;
  }
  
  .page {
    display: block;
    width: 30px;
    height: 45px;
    border: 4px solid #FFFFFF;
    border-left: 1px solid #8455b2;
    margin: 0;
    position: absolute;
    right: -4px;
    top: -4px;
    overflow: hidden;
    background: #8455b2;
    transform-style: preserve-3d;
    -webkit-transform-origin: left center;
    transform-origin: left center;
  }
  
  .book .page:nth-child(1) {
    -webkit-animation: pageTurn 1.2s cubic-bezier(0, .39, 1, .68) 1.6s infinite;
    animation: pageTurn 1.2s cubic-bezier(0, .39, 1, .68) 1.6s infinite;
  }
  
  .book .page:nth-child(2) {
    -webkit-animation: pageTurn 1.2s cubic-bezier(0, .39, 1, .68) 1.45s infinite;
    animation: pageTurn 1.2s cubic-bezier(0, .39, 1, .68) 1.45s infinite;
  }
  
  .book .page:nth-child(3) {
    -webkit-animation: pageTurn 1.2s cubic-bezier(0, .39, 1, .68) 1.2s infinite;
    animation: pageTurn 1.2s cubic-bezier(0, .39, 1, .68) 1.2s infinite;
  }
  
  
  /* Page turn 
  
  @-webkit-keyframes pageTurn {
    0% {
      -webkit-transform: rotateY( 0deg);
      transform: rotateY( 0deg);
    }
    20% {
      background: #4b1e77;
    }
    40% {
      background: rebeccapurple;
      -webkit-transform: rotateY( -180deg);
      transform: rotateY( -180deg);
    }
    100% {
      background: rebeccapurple;
      -webkit-transform: rotateY( -180deg);
      transform: rotateY( -180deg);
    }
  }
  
  @keyframes pageTurn {
    0% {
      transform: rotateY( 0deg);
    }
    20% {
      background: #4b1e77;
    }
    40% {
      background: rebeccapurple;
      transform: rotateY( -180deg);
    }
    100% {
      background: rebeccapurple;
      transform: rotateY( -180deg);
    }
  }
  
  
  /* Dots 
  
  @-webkit-keyframes Dots {
    0% {
      content: "";
    }
    33% {
      content: ".";
    }
    66% {
      content: "..";
    }
    100% {
      content: "...";
    }
  }
  
  @keyframes Dots {
    0% {
      content: "";
    }
    33% {
      content: ".";
    }
    66% {
      content: "..";
    }
    100% {
      content: "...";
    }
  } 
   Book Loader End */